












































import Vue from "vue";
import _ from "lodash";
import notificationMixin from "../../mixin/notification";
import store from "../../store/shop";
import ShopAPI from "../../common/api/api_shop";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import { PrintRule } from "../../model/entity/printRule";
import { PrintBit } from "../../model/entity/printJobSetting";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.extend({
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [notificationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      printRules: [],
      printSettings: [],
      errors: "",
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchPrintRuleList();
  },
  computed: {
    currentPrintRules() {
      return store.state.printRule;
    },
    currentPrinterNames() {
      return store.state.printerName;
    },
  },
  methods: {
    handleClickPrintRuleChange: function (bit, printRuleId, printerName) {
      if (this.isLoading) {
        return;
      }
      store.commit("setPrintRuleId", {bit, printRuleId});
      store.commit("setPrinterName", {bit, printerName});
      this.$emit("close");
    },
    handleClickClose() {
      this.$emit("close");
    },
    fetchPrintRuleList() {
      this.isLoading = true;
      let message = "";
      Promise.all([
        api
          .print_rules_index()
          .then((response) => {
            if (response.data.status != "success") {
              message =
                response.data.message ||
                translate("hall-0048", "データの取得に失敗しました。");
              this.showErrorNotification(message);
              this.errors = message;
              return;
            }
            this.printRules = response.data.data.rules;
            this.printSettings = response.data.data.settings;
          })
      ])
      .then(() => {
        this.isLoading = false;
        const current = this.currentPrinterNames[PrintBit.TYPE_SETTLE_AND_RECEIPT];
        if (!current) {
          this.setDefaultPrinter();
        }

        for (const row in this.printRules) {
          if (this.printRules[row].length > 1) {
            this.$parent.hasManualPrintRule = true;
          }
        }
      })
      .catch((err) => {
        this.isLoading = false;
        message =
          err.response.data.message ||
          translate("hall-0048", "データの取得に失敗しました。");
        this.showErrorNotification(message);
        this.errors = message;
      });
    },
    isSelectPrinter(bit: string, rule: PrintRule): string {
      const current = this.currentPrintRules[bit];
      if (!current && rule.rank === 0) {
        return 'Purple';
      }
      if (current === rule.id) {
        return 'Purple';
      }
      return '';
    },
    setChecked (value) {
      if (value) {
        return "checked";
      }
    },
    setDefaultPrinter() {
      const bit = PrintBit.TYPE_SETTLE_AND_RECEIPT;
      const rules = this.printRules[bit];
      if (!rules) {
        return;
      }
      const rule = rules
        .filter((row) => row.target_bit === bit)
        .find((row) => {
          return row.rank === 0;
        });

      if (!rule) {
        return;
      }
      this.handleClickPrintRuleChange(bit, rule.id, rule.printer_name);
    },
  },
});
